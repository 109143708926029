import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Accordion, Form} from "react-bootstrap";
import store from "../../store/store";
import {toggleFilter} from "../../store/attributes/attributes_slice";

function orderSort(ascending) {
    return function (attrA, attrB) {
        console.log({attrA, attrB});
        let a = attrA?.metadata?.filter(md => md.key === 'filter_order')[0]?.value;
        let b = attrB?.metadata?.filter(md => md.key === 'filter_order')[0]?.value;

        console.log({a, b});

        // equal items sort equally
        if (a === b) {
            return 0;
        }

        // nulls sort after anything else
        if (a === null) {
            return 1;
        }
        if (b === null) {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first
        if (ascending) {
            return a < b ? -1 : 1;
        }

        // if descending, highest sorts first
        return parseInt(a) < parseInt(b) ? 1 : -1;
    };
}

const onClickHandlerThunk = (eventKey, activeKeys, setActiveKeys) => () => {
    console.log(activeKeys);
    if (activeKeys.includes(eventKey)) {
        console.log(activeKeys.filter(key => key !== eventKey));
        setActiveKeys(activeKeys.filter(key => key !== eventKey));
    } else {
        setActiveKeys([...activeKeys, eventKey]);
    }
}

const BooleanFilter = attribute => {
    return (
        <Form key={`attribute-${attribute.slug}`} onChange={() => store.dispatch(toggleFilter(attribute))}>
            <Form.Check className={"text-nowrap"} type={"checkbox"}
                        id={`filter-${attribute.id}`}
                        label={attribute.name}/>
        </Form>
    );
}

const ProductFilterOption = attribute => {
    switch (attribute.inputType) {
        case "BOOLEAN":
            return BooleanFilter(attribute);
        default:
            return BooleanFilter(attribute);
    }
}


const ProductFilterSection = ({attributes, productType, eventKey, activeKeys, setActiveKeys}) => {

    let availableAttributes = Object.values(attributes).filter(attribute =>
        attribute.productTypes?.edges?.some(pt => pt.node.id === productType.id)
    );

    if (!availableAttributes.length) return;

    let ordered = availableAttributes.sort(orderSort(true));
    let headerText = productType.metadata?.filter(md => md.key === 'filter_section_text')[0]?.value;

    console.log({ordered});

    console.log({availableAttributes, productType, activeKeys});

    return (
        <Accordion.Item eventKey={eventKey}>
            <Accordion.Header className={"accordion-nopadding"}
                              onClick={onClickHandlerThunk(eventKey, activeKeys, setActiveKeys)}>
                <h6 className={"text-muted text-nowrap"}>{headerText || productType.name}</h6>
            </Accordion.Header>
            <Accordion.Body>
                {availableAttributes.map(ProductFilterOption)}
            </Accordion.Body>
        </Accordion.Item>
    );
}

const ProductFilter = (props) => {
    const [activeKeys, setActiveKeys] = useState([]);
    let uniqueProductTypes = Object.values(props.products.market)
        .reduce((prev, cur) => ({
            ...prev,
            [cur.productType.name]: cur.productType
        }), {});

    useEffect(() => {
        let uniqueProductTypes = Object.values(props.products.market)
            .reduce((prev, cur) => ({
                ...prev,
                [cur.productType.name]: cur.productType
            }), {});
        setActiveKeys([...Object.values(uniqueProductTypes).map((_, idx) => idx)])
    }, [props.products.market]);

    return (
        <Accordion flush alwaysOpen defaultActiveKey={[0]}>
            <Accordion.Item eventKey={0}>
                <Accordion.Header className={"accordion-nopadding"}>
                    <h5 className={"px-3 pt-1 text-uppercase section-header"}>Filters</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <Accordion flush alwaysOpen className={"accordion-noborder"} activeKey={activeKeys}>
                        {Object.values(uniqueProductTypes)
                            .map((productType, idx) => (
                                    <ProductFilterSection
                                        key={idx}
                                        attributes={props.attributes.attributes}
                                        productType={productType}
                                        eventKey={idx}
                                        activeKeys={activeKeys}
                                        setActiveKeys={setActiveKeys}/>
                                )
                            )
                        }
                    </Accordion>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const mapStateToProps = (state) => ({
    attributes: state.attributes,
    products: state.products,
    checkout: state.checkout
});

export default connect(mapStateToProps)(ProductFilter);