import React from "react";
import {ListGroup, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {useEffect} from "react";
import {createCheckoutThunk} from "../../store/checkout/checkout_slice";
import store from "../../store/store";
import CartLineItem from "./CartLineItem";

const Cart = props => {

    useEffect(() => {
        if (props.checkout.status === 'idle' && (!props.checkout.checkout || !props.checkout.checkout.id)) {
            store.dispatch(createCheckoutThunk({email: 'test@example.com'}));
        }
    }, [props.checkout]);

    return (
        <React.Fragment>
            <Row className={"border-bottom"}>
                <ListGroup className={"p-0 w-100 border-right-0 border-left-0"}>
                    {
                        props.checkout.checkout.lines &&
                        props.checkout.checkout.lines.length > 0 ?
                            props.checkout.checkout.lines.map(CartLineItem(props)) :
                            <div className={"d-flex justify-content-center w-100"}><i>Cart is empty</i></div>
                    }
                </ListGroup>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    checkout: state.checkout,
    products: state.products,
    navHandler: ownProps.navHandler
});

export default connect(mapStateToProps)(Cart);