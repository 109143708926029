import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import client from "../../services/apollo";
import {getShippingMethods} from "./get_shipping_methods";
import {pendingHandler, rejectedHandler} from "../status";

const initialState = {
    status: 'idle',
    error: null,
    methods: {}
};

export const fetchShippingMethodsThunk = createAsyncThunk(
    'shippingMethods/fetchShippingMethodsThunk',
    () =>
        client.query({
            query: getShippingMethods()
        }).catch(console.log)
);


const contextSlice = createSlice({
    name: 'shippingMethods',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchShippingMethodsThunk.pending, pendingHandler)
            .addCase(fetchShippingMethodsThunk.rejected, rejectedHandler)
            .addCase(fetchShippingMethodsThunk.fulfilled, (state, action) => {
                state.methods = action.payload['data']['shop']['availableShippingMethods'].reduce(
                    (obj, method) => {
                        if (method && method.__typename === 'ShippingMethod') {
                            return {
                                ...obj,
                                [method.name]: method
                            }
                        } else {
                            return obj;
                        }
                    },
                    {} // Empty object to init the reduce function
                );

                state.status = "fulfilled";
            })
    }
});

export default contextSlice.reducer;