import ProductListItem from "./ProductListItem";
import React from "react";
import {connect} from "react-redux";
import {BsCheckCircle} from "react-icons/bs";
import {Accordion} from "react-bootstrap";

const ProductTypeSelectionHeader = ({productType, checkout}) => {
    console.log(checkout);
    let limitReached = false;

    let limitMetadata = productType.metadata.filter(md => md.key === 'limit');
    let headerTextMetadata = productType.metadata.filter(md => md.key === 'header_text');
    let headerTextLimitMetadata = productType.metadata.filter(md => md.key === 'header_text_limit');

    if (limitMetadata) {
        let lines = checkout.checkout.lines?.filter(line => line.variant?.product?.productType?.id === productType.id);
        let count = lines.reduce((prev, cur) => prev + cur.quantity, 0);

        console.log({limitMetadata, count, test: count >= parseInt(limitMetadata.value)});

        if (count >= parseInt(limitMetadata[0]?.value)) {
            limitReached = true;
        }
    }


    if (limitReached) {
        return (
            <Accordion.Header className={"accordion-nopadding"}>
                <h5 className={"px-3 pt-1 text-uppercase section-header"}>
                    {headerTextLimitMetadata[0]?.value || "products added to order!"}
                </h5>
                <BsCheckCircle className={"btn-md m-2"}/>
            </Accordion.Header>
        );
    } else {
        return (
            <Accordion.Header className={"accordion-nopadding"}>
                <h5 className={"px-3 pt-1 text-uppercase section-header"}>
                    {headerTextMetadata[0]?.value || productType.name}
                </h5>
            </Accordion.Header>
        )
    }
}

const ProductSelectionList = ({limitReached, productType, products, filters}) => {
    let filteredProducts =
        Object.values(products)
            .filter(product => product.productType.id === productType.id)
            .filter(product => {
                    let filterArray = Object.values(filters);
                    console.log(filterArray);
                    let resultArray = filterArray.map(filter => {
                        if (!filter.productTypes?.edges?.some(edge => {
                            console.log({edge, productType});
                            return edge.node.id === productType.id;
                        })) {
                            return true;
                        }
                        console.log(product);
                        return product.attributes
                            .some(attr => {
                                if (attr.attribute.id !== filter.id) return false;
                                return attr.values.some(value => value.boolean)
                            })
                    });

                    return resultArray.every(result => result === true)
                }
            );

    return (
        <Accordion.Body>
            <div className={"pl-lg-3 pr-lg-3"}>
                {
                    !limitReached &&
                    filteredProducts.map(product => <ProductListItem key={product.id} device={product}/>)
                }
            </div>
        </Accordion.Body>
    );
}

const ProductListSection = (props) => {
    let limitReached = false;
    let limitMetadata = props.productType.metadata.filter(md => md.key === 'limit');

    if (limitMetadata) {
        let lines = props.checkout.checkout.lines?.filter(
            line => line.variant?.product?.productType?.id === props.productType.id
        );
        let count = lines.reduce((prev, cur) => prev + cur.quantity, 0);

        console.log({limitMetadata, count, test: count >= parseInt(limitMetadata.value)});

        if (count >= parseInt(limitMetadata[0]?.value)) {
            limitReached = true;
        }
    }

    return (
        <Accordion.Item eventKey={props.index}>
            <ProductTypeSelectionHeader
                limitReached={limitReached}
                checkout={props.checkout}
                productType={props.productType}/>
            <ProductSelectionList
                limitReached={limitReached}
                checkout={props.checkout}
                productType={props.productType}
                products={Object.values(props.products.market)}
                filters={props.attributes.filters}/>
        </Accordion.Item>
    );
}

const mapStateToProps = (state, ownProps) => ({
    index: ownProps.index,
    productType: ownProps.productType,
    attributes: state.attributes,
    products: state.products,
    checkout: state.checkout
});

export default connect(mapStateToProps)(ProductListSection);