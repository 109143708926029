import {connect} from "react-redux";
import Cart from "../cart/Cart";
import InstallationAddressFragment from "./InstallationAddressFragment";
import {Accordion, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import UserInformationFragment from "./UserInformationFragment";
import React, {useRef, useState} from "react";
import {BsCartCheck} from "react-icons/bs";
import {
    createOrderFromCheckoutThunk,
    updateCheckoutBillingAddressThunk,
    updateCheckoutEmailAddressThunk,
    updateCheckoutShippingAddressThunk,
    updateCheckoutShippingMethodThunk
} from "../../store/checkout/checkout_slice";
import store from "../../store/store";
import {addToast} from '../../store/toasts/toasts_slice';
import TermsAndConditions from "./TermsAndConditions";
import {useNavigate} from "react-router-dom";
import CodeApplied from "../code/CodeApplied";
import Code from "../code/Code";


const placeOrderHandler = (navigate, setLoading, checkout, scrollRef, tacRef, userInfoRef, addressFormRef) => () => {

    let userInfo = userInfoRef.current.values;
    let addressInfo = addressFormRef.current.values;

    let promises = [];

    if (
        userInfo.firstName === "" ||
        userInfo.lastName === "" ||
        userInfo.email === "" ||
        addressInfo.streetAddress1 === "" ||
        addressInfo.country === "" ||
        addressInfo.countryArea === "" ||
        addressInfo.postalCode === "" ||
    addressInfo.city === ""
    ) {
        store.dispatch(addToast({
            type: 'error',
            title: 'Validation Error',
            message: 'Please enter all required information'
        }));
        return;
    }


    if (checkout.isShippingRequired) {

        promises.push(store.dispatch(updateCheckoutShippingAddressThunk({token: checkout.token, userInfo, addressInfo}))
            .unwrap()
            .then(({data}) => {
                if (data['checkoutShippingAddressUpdate'].errors.length > 0) {
                    store.dispatch(addToast({
                        type: 'error',
                        title: 'Validation Error',
                        message: 'Unable to validate shipping address. Please verify the information is correct and try again'
                    }));
                } else {
                    let methods = store.getState().shippingMethods.methods;

                    if (methods['Default']) {
                        let method = methods['Default'];
                        return store.dispatch(updateCheckoutShippingMethodThunk({
                            checkoutId: checkout.id,
                            shippingMethodId: method.id
                        }))
                            .unwrap()
                            .then(({data}) => {
                                console.log(data);
                                if (data['checkoutDeliveryMethodUpdate'].errors.length > 0) {
                                    store.dispatch(addToast({
                                        type: 'error',
                                        title: 'Validation Error',
                                        message: 'Unable to validate shipping address. Please verify the information is correct and try again'
                                    }));
                                }
                                return data['checkoutDeliveryMethodUpdate'];
                            });
                    }
                }

                return data['checkoutShippingAddressUpdate'];
            }));
    }

    promises.push(store.dispatch(updateCheckoutBillingAddressThunk({token: checkout.token, userInfo, addressInfo}))
        .unwrap()
        .then(({data}) => {
            if (data['checkoutBillingAddressUpdate'].errors.length > 0) {
                store.dispatch(addToast({
                    type: 'error',
                    title: 'Validation Error',
                    message: 'Unable to validate billing address. Please verify the information is correct and try again'
                }));
            }
            return data['checkoutBillingAddressUpdate'];
        }));

    promises.push(store.dispatch(updateCheckoutEmailAddressThunk({token: checkout.token, userInfo}))
        .unwrap()
        .then(({data}) => {
            if (data['checkoutEmailUpdate'].errors.length > 0) {
                store.dispatch(addToast({
                    type: 'error',
                    title: 'Validation Error',
                    message: 'Unable to validate email address. Please verify the information is correct and try again'
                }));
            }
            return data['checkoutEmailUpdate'];
        }));

    setLoading(true);

    Promise.all(promises)
        .then(values => {

            console.log(values);

            let isError = values.filter(value => value.errors?.length > 0);
            if (isError.length) {
                setLoading(false);
                return;
            }

            let checkout = store.getState().checkout.checkout;
            if (!checkout) {
                setLoading(false);
                return;
            }

            store.dispatch(createOrderFromCheckoutThunk(checkout.id))
                .unwrap()
                .then(({data}) => {
                    console.log(data);
                    if (data.data['orderCreateFromCheckout']?.errors?.length === 0) {
                        navigate("/confirmation");
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
}

const CompleteCheckout = props => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const tacRef = useRef();
    const userInfoRef = useRef();
    const addressFormRef = useRef();
    const scrollRef = useRef();

    if (loading) {
        return (
            <div className={'h-100'} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Spinner variant={'primary'} animation={'border'}/>
            </div>
        )
    }

    return (
        <Row className={"w-100 pt-3 mx-auto"}>
            <Col className={"col-12 col-lg-9"}>
                <div className={"d-flex flex-column shadow p-lg-3 justify-content-center"}>
                    <Accordion flush alwaysOpen defaultActiveKey={['0', '1', '2']}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>User Information</Accordion.Header>
                            <Accordion.Body>
                                <UserInformationFragment innerRef={userInfoRef}/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Installation Address</Accordion.Header>
                            <Accordion.Body>
                                <InstallationAddressFragment innerRef={addressFormRef}/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Terms and Conditions</Accordion.Header>
                            <Accordion.Body>
                                <TermsAndConditions/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Col>
            <Col className={"col-12 col-lg-3"}>
                <div className={"cart shadow p-3"}>
                    <div className={"pb-2"}>
                        <div key={"cart-checkout-header"} className={"pb-2 border-bottom"}>
                            <div className={"d-flex rounded-3 bg-white justify-content-center"}>
                                <h5 style={{color: "#2e5c7a"}}
                                    className={"px-3 pt-1 text-uppercase section-header"}>your order</h5>
                            </div>
                        </div>
                        <Cart key={"cart-component"} hideRemoveButtons={true}/>
                    </div>
                    <Button className={'ml-2 mr-2 pr-3 pl-3'}
                            variant={"success"}
                            onClick={placeOrderHandler(navigate, setLoading, props.checkout.checkout, scrollRef, tacRef, userInfoRef, addressFormRef)}>
                        <BsCartCheck/>
                        <span>    Place Order</span>
                    </Button>
                </div>
                <div className={"shadow cart border-0 pt-3"}>
                    <Card>
                        <Card.Title className={"text-center"}>
                            <div key={"cart-promo-header"} className={"pb-2 border-bottom"}>
                                <div className={"d-flex rounded-3 bg-white justify-content-center"}>
                                    <h6 style={{color: "#2e5c7a"}}
                                        className={"px-3 pt-2 text-uppercase section-header"}>
                                        <span>Add Promo Code</span>
                                    </h6>
                                </div>
                            </div>
                        </Card.Title>
                        <Card.Body>
                            {
                                props.checkout.checkout?.voucherCode ?
                                    (<CodeApplied/>) :
                                    (<Code/>)
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    )
}


const mapStateToProps = (state) => ({
    products: state.products,
    checkout: state.checkout
});

export default connect(mapStateToProps)(CompleteCheckout);