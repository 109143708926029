import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import client from "../../services/apollo";
import {getShippingZones} from "./get_shipping_zones";

const initialState = {
    status: 'idle',
    error: null,
    zones: {}
};

export const fetchShippingZonesThunk = createAsyncThunk(
    'shippingZones/fetchShippingZonesThunk',
    (args) =>
        client.query({
            query: getShippingZones(args)
        }).catch(console.log)
);

const contextSlice = createSlice({
    name: 'shippingMethods',
    initialState
});

export default contextSlice.reducer;