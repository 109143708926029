import React from 'react';
import {Form, InputGroup} from "react-bootstrap";


const customChangeHandler = handleChange => (event) => {
    event.target.value = event.target.value.toUpperCase();
    handleChange(event);
}

const CodeInput = ({
                       values,
                       errors,
                       handleChange,
                       name,
                       validFeedback,
                       disabled = false,
                       hasValidation = false
                   }) => (
    <React.Fragment>
        <InputGroup>
            <Form.Control
                key={name}
                name={name}
                disabled={disabled}
                value={values[name]}
                type={'string'}
                isValid={hasValidation ? values[name] && !errors[name] : null}
                isInvalid={hasValidation ? !!errors[name] : null}
                onChange={customChangeHandler(handleChange)}/>

            <Form.Control.Feedback key={'valid-feedback'} type={'valid'}>
                {validFeedback}
            </Form.Control.Feedback>
            <Form.Control.Feedback key={'invalid-feedback'} type={'invalid'}>
                {errors[name]}
            </Form.Control.Feedback>
        </InputGroup>
    </React.Fragment>
);

export default CodeInput;