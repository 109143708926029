const PENDING = 'pending';
const REJECTED = 'rejected';
const FULFILLED = 'fulfilled';

const rejectedHandler = state => {
    state.status = REJECTED;
};

const pendingHandler = state => {
    state.status = PENDING;
};

export {
    PENDING, REJECTED, FULFILLED, pendingHandler, rejectedHandler
}