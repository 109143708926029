import React from 'react';
import {Form, InputGroup} from "react-bootstrap";

const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return cleaned;
}

const StringInput = ({
                         values,
                         errors,
                         text,
                         handleBlur,
                         handleChange,
                         setFieldValue,
                         name,
                         disabled = false,
                         hasValidation = false,
                         validFeedback,
                         required = false
                     }) => (
    <React.Fragment>
        <label className="mb-1 text-decoration-underline">{text}</label>
        {required && <span title="Required" className={"small text-muted"}> *</span>}
        <InputGroup>
            <Form.Control
                key={name}
                name={name}
                disabled={disabled}
                value={values[name]}
                type={'string'}
                isValid={hasValidation ? values[name] && !errors[name] : null}
                isInvalid={hasValidation ? !!errors[name] : null}
                onBlur={event => {
                    setFieldValue('phone', formatPhoneNumber(values['phone']));
                    handleBlur(event);
                }}
                onChange={handleChange}/>

            <Form.Control.Feedback key={'valid-feedback'} type={'valid'}>
                {validFeedback}
            </Form.Control.Feedback>
            <Form.Control.Feedback key={'invalid-feedback'} type={'invalid'}>
                {errors[name]}
            </Form.Control.Feedback>
        </InputGroup>
    </React.Fragment>
);

export default StringInput;