import {connect} from "react-redux";
import React, {useEffect} from "react";
import store from "../../store/store";
import {fetchMarketProductsThunk} from "../../store/products/products_slice";
import Cart from '../cart/Cart';
import {Accordion, Button, Card, Col, Row} from "react-bootstrap";
import {BsCartCheck} from "react-icons/bs";
import {fetchShippingMethodsThunk} from "../../store/shippingMethods/shipping_methods_slice";
import ProductTypeSection from "./ProductListSection";
import ProductFilter from "./ProductFilter";
import {fetchAttributesThunk} from "../../store/attributes/attributes_slice";
import {useNavigate} from "react-router-dom";
import Code from "../code/Code";
import CodeApplied from "../code/CodeApplied";

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


const CheckoutView = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        const marketCookie = getCookie("checkout.market");
        let marketObj = {};

        if (marketCookie) {
            marketObj = JSON.parse(marketCookie);
        }

        if (props.isBusiness) {
            store.dispatch(fetchMarketProductsThunk({
                collectionIds: ["Q29sbGVjdGlvbjoy"]
            }));
        } else {
            fetch(`${process.env.REACT_APP_CHECKOUT_API}/markets/fetch-products-by-market`, {
                method: "POST",
                body: JSON.stringify({
                    marketId: marketObj.MARKET_ID || 1
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(json => {
                    if (json?.productIds?.length) {
                        store.dispatch(fetchMarketProductsThunk({
                            productIds: json.productIds
                        }));
                    }
                });
        }

    }, [props.isBusiness]);

    useEffect(() => {
        store.dispatch(fetchShippingMethodsThunk());
    }, []);

    useEffect(() => {
        store.dispatch(fetchAttributesThunk());
    }, []);

    let uniqueProductTypes = Object.values(props.products.market)
        .reduce((prev, cur) => ({
            ...prev,
            [cur.productType.name]: cur.productType
        }), {});


    console.log(uniqueProductTypes);
    let defaultKeys = Object.values(uniqueProductTypes).map((pt, index) => index);
    console.log({defaultKeys, other: [0, 1]});

    return (
        <Row className={"w-100 pt-3 mx-auto"}>
            <Col className={"col-12 col-lg-3"}>
                <div className={"d-flex flex-column shadow p-3 justify-content-center"}>
                    <ProductFilter/>
                </div>
            </Col>
            <Col className={"col-12 col-lg-6"}>
                <div className={"d-flex flex-column shadow p-3 justify-content-center"}>
                    <Accordion flush alwaysOpen defaultActiveKey={[0, 1, 2]}>
                        {
                            Object.values(uniqueProductTypes)
                                .filter(productType =>
                                    productType.metadata.filter(md => md.key === 'show_in_store')[0]?.value !== 'false')
                                .sort((a, b) => {
                                    let aOrderMetadata = a.metadata.filter(md => md.key === 'header_order');
                                    let bOrderMetadata = b.metadata.filter(md => md.key === 'header_order');

                                    let aOrder = aOrderMetadata[0]?.value || -1;
                                    let bOrder = bOrderMetadata[0]?.value || -1;

                                    console.log({a: parseInt(aOrder), b: parseInt(bOrder)});

                                    return parseInt(bOrder) - parseInt(aOrder);
                                })
                                .map((productType, idx) => (
                                    <ProductTypeSection key={idx} productType={productType} index={idx}/>
                                ))
                        }
                    </Accordion>
                </div>
            </Col>
            <Col className={"col-12 col-lg-3"}>
                <div className={"shadow p-3 cart"}>
                    <div key={"cart-checkout-header"} className={"pb-2 border-bottom"}>
                        <div className={"d-flex rounded-3 bg-white justify-content-center"}>
                            <h5 style={{color: "#2e5c7a"}} className={"px-3 pt-1 text-uppercase section-header"}>your
                                order</h5>
                        </div>
                    </div>
                    <Cart key={"cart-component"}/>
                    <Button key={"cart-checkout-button"} className={'ml-2 mr-2 mt-2 pr-3 pl-3'}
                            variant={"success"}
                            disabled={!(props.checkout.checkout?.lines?.length > 0)}
                            onClick={() => navigate("/complete")}>
                        <BsCartCheck/>
                        <span>    {props.submitText ? props.submitText : 'Continue'}</span>
                    </Button>
                </div>
                <div className={"shadow cart border-0 pt-3"}>
                    <Card>
                        <Card.Title className={"text-center"}>
                            <div key={"cart-promo-header"} className={"pb-2 border-bottom"}>
                                <div className={"d-flex rounded-3 bg-white justify-content-center"}>
                                    <h6 style={{color: "#2e5c7a"}}
                                        className={"px-3 pt-2 text-uppercase section-header"}>
                                        <span>Add Promo Code</span>
                                    </h6>
                                </div>
                            </div>
                        </Card.Title>
                        <Card.Body>
                            {
                                props.checkout.checkout?.voucherCode ?
                                    (<CodeApplied/>) :
                                    (<Code/>)
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    );
}


const mapStateToProps = (state, ownProps) => ({
    products: state.products,
    checkout: state.checkout,
    navHandler: ownProps.navHandler
});

export default connect(mapStateToProps)(CheckoutView);