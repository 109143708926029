import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import client from "../../services/apollo";
import {getProducts} from "./get_products";
import {pendingHandler, rejectedHandler} from "../status";

const initialState = {
    status: 'idle',
    error: null,
    services: {},
    devices: {},
    market: {},
    managedServices: {}
};

export const fetchMarketProductsThunk = createAsyncThunk(
    'products/fetchMarketProductsThunk',
    (args) =>
        client.query({
            query: getProducts(),
            variables: {
                ...args,
                channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL
            }
        }).catch(console.log)
)

const contextSlice = createSlice({
    name: 'products',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchMarketProductsThunk.pending, pendingHandler)
            .addCase(fetchMarketProductsThunk.rejected, rejectedHandler)
            .addCase(fetchMarketProductsThunk.fulfilled, (state, action) => {
                state.market = action.payload['data']['products']['edges'].reduce(
                    (obj, edge) => {
                        if (edge && edge.__typename === 'ProductCountableEdge') {
                            return {
                                ...obj,
                                [edge['node']['id']]: edge['node']
                            }
                        } else {
                            return obj;
                        }
                    },
                    {} // Empty object to init the reduce function
                );

                state.status = "fulfilled";
            })
    }
});

export default contextSlice.reducer;