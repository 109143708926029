import {gql} from "@apollo/client";

const getShippingMethods = () => gql`
    query MyQuery {
        shop {
            availableShippingMethods(channel: "usd") {
                id
                name
                active
            }
        }
    }`;

export {
    getShippingMethods
}