import {gql} from "@apollo/client";

const getProducts = () => gql`
query MyQuery ($productIds: [ID!], $channel: String, $collectionIds: [ID!]) {
  products(
    first: 100
    filter: {ids: $productIds, collections: $collectionIds}
    sortBy: {field: MINIMAL_PRICE, direction: DESC}
    channel: $channel
  ) {
    edges {
      node {
        id
        name
        metadata {
          key
          value
        }
        isAvailable
        rating
        pricing {
          priceRange {
            start {
              gross {
                amount
                currency
              }
            }
          }
        }
        slug
        productType {
          id
          name
          kind
          metadata {
            key
            value
          }
        }
        description
        media {
          id
          url(size: 250)
          type
          productId
        }
        variants {
          id
          name
        }
        attributes {
          values {
            boolean
            id
            value
            name
            inputType
          }
          attribute {
            id
            name
          }
        }
      }
    }
  }
}
`;

export {
    getProducts
}