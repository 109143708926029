import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import client from "../../services/apollo";
import {createCheckout} from "./get_checkouts";
import {addCheckoutLineItem} from "./add_checkout_line";
import {checkoutAddPromoCode} from "./add_promo_code";
import {removeCheckoutLineItem} from "./remove_checkout_line";
import store from "../store";
import fetchWrapper from "../../utils/fetchWrapper";
import {
    checkoutBillingAddressUpdate,
    checkoutEmailAddressUpdate,
    checkoutShippingAddressUpdate
} from "./checkout_update";
import {updateShippingMethod} from "../shippingMethods/update_shipping_method";
import {FULFILLED, pendingHandler, rejectedHandler} from "../status";
import {checkoutRemovePromoCode} from "./remove_promo_code";

const initialState = {
    status: 'idle',
    error: null,
    checkout: {
        lines: []
    },
    serviceAdded: false,
    deviceAdded: false
};

const fulfilledHandler = queryName => (state, action) => {
    console.log(action.payload.data);
    let checkout = action.payload.data[queryName].checkout;
    if (checkout !== null) state.checkout = checkout;
    state.status = FULFILLED;
}

export const setServiceAddedThunk = createAsyncThunk(
    'checkout/setServiceAddedThunk',
    (args) =>
        client.mutate({
            mutation: checkoutBillingAddressUpdate(args),
        })
);

export const updateCheckoutBillingAddressThunk = createAsyncThunk(
    'checkout/updateCheckoutBillingAddressThunk',
    (args) =>
        client.mutate({
            mutation: checkoutBillingAddressUpdate(args),
        })
);

export const updateCheckoutEmailAddressThunk = createAsyncThunk(
    'checkout/updateCheckoutEmailAddressThunk',
    (args) =>
        client.mutate({
            mutation: checkoutEmailAddressUpdate(args),
        })
);

export const updateCheckoutShippingAddressThunk = createAsyncThunk(
    'checkout/updateCheckoutShippingAddressThunk',
    (args) =>
        client.mutate({
            mutation: checkoutShippingAddressUpdate(args),
        })
);

export const updateCheckoutShippingMethodThunk = createAsyncThunk(
    'checkout/updateCheckoutShippingMethodThunk',
    (args) =>
        client.mutate({
            mutation: updateShippingMethod(args)
        }).catch(console.log)
);

export const createCheckoutThunk = createAsyncThunk(
    'checkout/createCheckoutThunk',
    (args) =>
        client.mutate({
            mutation: createCheckout(),
            variables: {
                email: args.email,
                channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL
            }
        })
);

export const createOrderFromCheckoutThunk = createAsyncThunk(
    'checkout/createOrderFromCheckoutThunk',
    (checkoutId) =>
        fetchWrapper(`${process.env.REACT_APP_CHECKOUT_API}/actions/order-create-from-checkout`, {
            method: 'POST',
            body: JSON.stringify({checkoutId})
        })
);

export const addCheckoutLineThunk = createAsyncThunk(
    'checkout/addCheckoutLineThunk',
    (variantId) =>
        client.mutate({
            mutation: addCheckoutLineItem(store.getState().checkout.checkout.token, variantId)
        }).catch(console.log)
)

export const checkoutRemovePromoCodeThunk = createAsyncThunk(
    'checkout/checkoutRemovePromoCodeThunk',
    (promoCode) =>
        client.mutate({
            mutation: checkoutRemovePromoCode(store.getState().checkout.checkout.token, promoCode)
        }).catch(console.log)
)

export const checkoutAddPromoCodeThunk = createAsyncThunk(
    'checkout/checkoutAddPromoCodeThunk',
    (promoCode) =>
        client.mutate({
            mutation: checkoutAddPromoCode(store.getState().checkout.checkout.token, promoCode)
        }).catch(console.log)
)

export const removeCheckoutLineThunk = createAsyncThunk(
    'checkout/removeCheckoutLineThunk',
    (linesIds) =>
        client.mutate({
            mutation: removeCheckoutLineItem(),
            variables: {
                token: store.getState().checkout.checkout.token,
                linesIds: linesIds
            }
        }).catch(console.log)
)

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(createCheckoutThunk.pending, pendingHandler)
            .addCase(createCheckoutThunk.rejected, rejectedHandler)
            .addCase(createCheckoutThunk.fulfilled, fulfilledHandler('checkoutCreate'))
            .addCase(addCheckoutLineThunk.pending, pendingHandler)
            .addCase(addCheckoutLineThunk.rejected, rejectedHandler)
            .addCase(addCheckoutLineThunk.fulfilled, fulfilledHandler('checkoutLinesAdd'))
            .addCase(checkoutAddPromoCodeThunk.pending, pendingHandler)
            .addCase(checkoutAddPromoCodeThunk.rejected, rejectedHandler)
            .addCase(checkoutAddPromoCodeThunk.fulfilled, fulfilledHandler('checkoutAddPromoCode'))
            .addCase(checkoutRemovePromoCodeThunk.pending, pendingHandler)
            .addCase(checkoutRemovePromoCodeThunk.rejected, rejectedHandler)
            .addCase(checkoutRemovePromoCodeThunk.fulfilled, fulfilledHandler('checkoutRemovePromoCode'))
            .addCase(removeCheckoutLineThunk.pending, pendingHandler)
            .addCase(removeCheckoutLineThunk.rejected, rejectedHandler)
            .addCase(removeCheckoutLineThunk.fulfilled, fulfilledHandler('checkoutLinesDelete'))
            .addCase(updateCheckoutBillingAddressThunk.pending, pendingHandler)
            .addCase(updateCheckoutBillingAddressThunk.rejected, rejectedHandler)
            .addCase(updateCheckoutBillingAddressThunk.fulfilled, fulfilledHandler('checkoutBillingAddressUpdate'))
            .addCase(updateCheckoutEmailAddressThunk.pending, pendingHandler)
            .addCase(updateCheckoutEmailAddressThunk.rejected, rejectedHandler)
            .addCase(updateCheckoutEmailAddressThunk.fulfilled, fulfilledHandler('checkoutEmailUpdate'))
            .addCase(updateCheckoutShippingAddressThunk.pending, pendingHandler)
            .addCase(updateCheckoutShippingAddressThunk.rejected, rejectedHandler)
            .addCase(updateCheckoutShippingAddressThunk.fulfilled, fulfilledHandler('checkoutShippingAddressUpdate'))
            .addCase(updateCheckoutShippingMethodThunk.pending, pendingHandler)
            .addCase(updateCheckoutShippingMethodThunk.rejected, rejectedHandler)
            .addCase(updateCheckoutShippingMethodThunk.fulfilled, fulfilledHandler('checkoutDeliveryMethodUpdate'))
            .addCase(createOrderFromCheckoutThunk.pending, pendingHandler)
            .addCase(createOrderFromCheckoutThunk.rejected, rejectedHandler)
            .addCase(createOrderFromCheckoutThunk.fulfilled, (state, action) => {
                let order = action.payload.data.data['orderCreateFromCheckout'].order;

                if (order !== null) {
                    state.order = order;
                }
                state.status = "fulfilled";
            })
    }
});

export default checkoutSlice.reducer;