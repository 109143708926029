import {connect} from "react-redux";

const Confirmation = (props) => {
    return (
        <div className={"d-flex justify-content-center mt-lg-4"}>
            <div className="block-heading text-dark">
                <h2 className="text-info">Thank you!</h2>
                <p>We have received your order!</p>
                <br/>
                <p>We will be in contact with you as soon as possible to provide you with an update related to your installation.</p>
                <p>If you have any questions in the meantime, feel free to contact our support or provisioning department at 517-999-9999.</p>
                <br/>
                <span><b>Order ID:</b></span>
                <br/>
                <span className={'text-primary'}>&emsp;{props.checkout.order.id}</span>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    products: state.products,
    checkout: state.checkout
});

export default connect(mapStateToProps)(Confirmation);