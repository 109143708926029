import {gql} from "@apollo/client";

const getAttributes = () => gql`
query GetAttributes {
  attributes(first: 100) {
    edges {
      node {
        id
        name
        slug
        inputType
        entityType
        metadata {
          key
          value
        }
        unit
        productTypes(first: 100) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
`;

export {
    getAttributes
}