import {Col, Row} from "react-bootstrap";
import React from "react";
import StringInput from "../inputs/StringInput";
import PhoneInput from "../inputs/PhoneInput";

const UserForm = (props) => (
    <React.Fragment>
        <Row className={"mb-3 justify-content-lg-evenly"}>
            <Col>
                <StringInput name={"firstName"} required={true} text={"First Name"} {...props} />
            </Col>
            <Col>
                <StringInput name={"lastName"} required={true} text={"Last Name"} {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col className={"col-lg-8"}>
                <StringInput name={"email"} text={"Email"} required={true} hasValidation={true} {...props}  />
            </Col>
            <Col className={"col-lg-4"}>
                <PhoneInput name={"phone"} text={"Phone"} required={true} hasValidation={true} {...props} />
            </Col>
        </Row>
    </React.Fragment>
);

export default UserForm;