import {gql} from "@apollo/client";

export const removeCheckoutLineItem = () => gql`
mutation removeCheckoutLineItem($token: UUID, $linesIds: [ID!]!) {
  checkoutLinesDelete(
    token: $token,
    linesIds: $linesIds
  ) {
    checkout {
      id
      token
      totalPrice {
        gross {
          amount
          currency
        }
      }
      voucherCode
      isShippingRequired
      created
      lines {
        id
        quantity
        totalPrice {
          currency
          gross {
            amount
          }
        }
        undiscountedTotalPrice {
          amount
        }
        undiscountedUnitPrice {
          amount
        }
        variant {
          id
          name
          metadata {
            key
            value
          }
          product {
            id
            name
            metadata {
              key
              value
            }
            productType {
              id
              name
              metadata {
                key
                value
              }
            }
          }
          pricing {
            price {
              gross {
                amount
                currency
              }
            }
          }
        }
      }
    }
  }
}`