import {gql} from "@apollo/client";

const updateShippingMethod = ({checkoutId, shippingMethodId}) => gql`
    mutation updateShippingMethod {
        checkoutDeliveryMethodUpdate(
            deliveryMethodId: "${shippingMethodId}"
            id: "${checkoutId}"
        ) {
            errors {
                field
                message
                code
            }
            checkout {
                id
                token
                totalPrice {
                    gross {
                        amount
                        currency
                    }
                }
                isShippingRequired
                created
                lines {
                    id
                    quantity
                    variant {
                        id
                        name
                        metadata {
                            key
                            value
                        }
                        product {
                            id
                            name
                            metadata {
                                key
                                value
                            }
                            productType {
                                id
                                name
                                metadata {
                                    key
                                    value
                                }
                            }
                        }
                        pricing {
                            price {
                                gross {
                                    amount
                                    currency
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;

export {
    updateShippingMethod
}