const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export default async function fetchWrapper(url, options) {
    let newOptions = {
        ...options,
        headers: new Headers({
            ...defaultHeaders
        })
    };

    return fetch(url, newOptions)
        .then(async response => {
            try {
                let responseBody = await response.json();

                if (response.ok) {
                    return responseBody;
                } else {
                    return Promise.reject(responseBody);
                }
            } catch (e) {
                return Promise.reject({message: 'Response body was not JSON', error: e});
            }
        });
};