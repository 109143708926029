import {Formik} from "formik";
import UserForm from "../../components/forms/UserForm";
import {EMAIL_REGEX} from "../../data/regex";
import {formatPhoneNumber} from "../../utils/formatter";

const getInitialValues = () => {
    return {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    }
};

const formValidator = ({email, phone}) => {
    const errors = {};

    if (!EMAIL_REGEX.test(email)) {
        errors.email = "Please enter a valid email"
    }

    if (phone !== "" && !formatPhoneNumber(phone)) {
        errors.phone = "Please enter a valid phone number"
    }

    return errors;
};

const UserInformationFragment = ({innerRef}) => {

    return (
        <Formik validateOnBlur
                enableReinitialize
                innerRef={innerRef}
                initialValues={getInitialValues()}
                validate={formValidator}>
            {UserForm}
        </Formik>
    )
}

export default UserInformationFragment;