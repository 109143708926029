import {Formik} from "formik";
import AddressForm from "../../components/forms/AddressForm";

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const getInitialValues = () => {
    const locationCookie = getCookie("checkout.location");
    let locationObj = {};

    if (locationCookie) {
        locationObj = JSON.parse(locationCookie);
    }

    console.log(locationObj);


    return {
        country: locationObj.city || "US",
        streetAddress1: locationObj.street || "",
        streetAddress2: locationObj.city || "",
        countryArea: locationObj.state || "",
        postalCode: locationObj.zip_code || "",
        city: locationObj.city || ""
    }
};

const formValidator = ({formValues}) => {
    const errors = {};

    if (formValues)

    //Validation logic here

    return errors;
};

const InstallationAddressFragment = props => {

    return (
        <Formik validateOnBlur
                enableReinitialize
                innerRef={props.innerRef}
                initialValues={getInitialValues()}
                validate={formValidator}>
            {AddressForm}
        </Formik>
    )
}

export default InstallationAddressFragment;