import React from "react";
import {connect} from "react-redux";
import {Formik} from "formik";
import CodeForm from "../../components/forms/CodeForm";
import store from "../../store/store";
import {checkoutAddPromoCodeThunk} from "../../store/checkout/checkout_slice";
import {addToast} from "../../store/toasts/toasts_slice";

const getInitialValues = () => ({
    code: ""
});

const formValidator = () => {
    return {};
};

const submitHandler = async (values, {setSubmitting}) => {
    let checkoutState = store.getState().checkout.checkout;
    console.log(checkoutState.lines);
    if (checkoutState.lines.length !== 0) {
        let result = await store.dispatch(checkoutAddPromoCodeThunk(values.code));
        if (result?.payload?.data?.checkoutAddPromoCode?.checkout === null) {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Promo Code Error',
                message: 'Promo code not found for the selected products.\n Please verify the code is correct and try again'
            }))
        }
    } else {
        store.dispatch(addToast({
            type: 'warning',
            title: 'Promo Code Error',
            message: 'Add items to cart before applying a promo code'
        }))
    }

    setSubmitting(false);
}

const Code = () => {
    return (
        <Formik validateOnBlur
                enableReinitialize
                initialValues={getInitialValues()}
                validate={formValidator}
                onSubmit={submitHandler}>
            {CodeForm}
        </Formik>
    )
};

const mapStateToProps = (state, ownProps) => ({
    navHandler: ownProps.navHandler
});

export default connect(mapStateToProps)(Code);