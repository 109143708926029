import React from 'react';
import HTMLReactParser from "html-react-parser";
import {Button, Col, Row} from "react-bootstrap";
import {BsCartPlus} from "react-icons/bs";
import store from "../../store/store";
import {addCheckoutLineThunk} from "../../store/checkout/checkout_slice";
import {PENDING} from "../../store/status";

const TextBlock = (block) => {
    console.log(block);
    return (
        <p key={block.id} style={{fontSize: ".90rem"}} className={"pt-1"}>{HTMLReactParser(block.data.text)}</p>
    )
}

const ListBlock = (block) => {
    if (block.data.style === 'unordered') {
        return (
            <ul key={block.id} className={"text-muted"}>
                {block.data.items.map((item, idx) => (<li key={idx} className={"m-0"}>{HTMLReactParser(item)}</li>))}
            </ul>
        )
    } else {
        return (
            <ol key={block.id} className={"text-muted"}>
                {block.data.items.map((item, idx) => (<li key={idx} className={"m-0"}>{HTMLReactParser(item)}</li>))}
            </ol>
        )
    }
}

const HeaderBlock = (block) => {
    let blockObj = HTMLReactParser(block.data.text);
    switch (block.data.level) {
        case 1:
            return <h4 key={block.id} className={"text-black-50"}>{blockObj}</h4>
        case 2:
            return <h5 key={block.id} className={"text-black-50"}>{blockObj}</h5>
        case 3:
            return <h6 key={block.id} className={"text-black-50"}>{blockObj}</h6>
        default:
            return <h5 key={block.id} className={"text-black-50"}>{blockObj}</h5>
    }
}

const DescriptionBlock = block => {
    switch (block.type) {
        case 'paragraph':
            return TextBlock(block);
        case 'list':
            return ListBlock(block);
        case 'header':
            return HeaderBlock(block);
        default: {
            return <p key={block.id}>{block.text}</p>;
        }

    }
};

const addDeviceToCart = (device) => () => {
    if (store.getState().checkout.status !== 'pending') {
        let bundledVariants = device.metadata.filter(md => md.key === 'bundled_variants');
        if (bundledVariants[0]) {

            let variants = bundledVariants[0].value.split(',');
            Promise.all(variants.map(variantId =>
                store.dispatch(addCheckoutLineThunk(variantId)).unwrap()
            )).then((results) => {
                let addedAll = true;
                results.forEach(result => {
                    if (!result.data['checkoutLinesAdd'].checkout) {
                        addedAll = false;
                    }
                });
                if (addedAll) {
                    store.dispatch(addCheckoutLineThunk(device.variants[0].id))
                } else {
                    console.log("Failed to add bundled items");
                }
            })
        } else {
            store.dispatch(addCheckoutLineThunk(device.variants[0].id));
        }
    }
}

const ProductListItem = ({device}) => {
    let showInStoreMetadata = device.metadata.filter(md => md.key === 'show_in_store');
    let subscriptionMetadata = device.metadata.find(md => md.key === 'subscription');
    let subscriptionPeriodMetadata = device.metadata.find(md => md.key === 'subscription_period');
    let displayName = device.metadata.find(md => md.key === 'display_name');
    let showInStore = true;

    console.log()

    if (showInStoreMetadata.length) {
        showInStore = showInStoreMetadata[0]?.value !== 'false';
    }

    if (!showInStore) return;

    console.log(device);
    return (
        <div className={"w-100 mt-4 border-bottom"} style={{fontFamily: 'Montserrat, sans-serif'}}>
            <Row className={"flex-nowrap"}>
                <Col className={"col-10 col-md-11 col-lg-11"}>
                    <Row className={"justify-content-between"}>
                        <Col className={"col-auto"}>
                            <h5 className={"text-primary"}>{displayName?.value || device.name}</h5>
                            <div className={"ps-3"}>
                                {device.description && JSON.parse(device.description).blocks.map(DescriptionBlock)}
                            </div>
                        </Col>

                        {device.media[0] && (
                            <Col className={"col-auto my-2"}>
                                <img src={device.media[0]?.url}
                                     className={"img-fluid img-thumbnail"}
                                     alt={'thumbnail'}/>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col className={"col-2 col-md-1 col-lg-1"}>
                    <Row>
                        <Button variant={"outline-success"}
                                disabled={store.getState().checkout.status === PENDING}
                                className={"btn-sm"}
                                onClick={addDeviceToCart(device)}>
                            <BsCartPlus size={"20"}/>
                        </Button>
                    </Row>
                    <Row>
                        <Col className={"p-0 text-center"}>
                            <span>$</span>
                            <span>{device.pricing?.priceRange?.start?.gross?.amount}</span>
                            {
                                subscriptionMetadata?.value &&
                                <sub className={"inline-block"}>/{subscriptionPeriodMetadata?.value}</sub>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}

export default ProductListItem;