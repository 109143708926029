import {Col, Row} from "react-bootstrap";
import React from "react";
import StringInput from "../inputs/StringInput";

const AddressForm = (props) => (
    <React.Fragment>
        <Row className={"mb-3"}>
            <Col>
                <StringInput name={"streetAddress1"} text={"Address"} required={true} {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <StringInput name={"streetAddress2"} text={"Address Line 2"} {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <StringInput name={"countryArea"} text={"State"} required={true} {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <StringInput name={"city"} text={"City"} required={true} {...props} />
            </Col>
            <Col>
                <StringInput name={"postalCode"} text={"Zip / Postal Code"} required={true} {...props} />
            </Col>
        </Row>
    </React.Fragment>
);

export default AddressForm;