import React from 'react';
import TopNav from "./components/layout/topnav";
import {ToastContainer} from "react-bootstrap";
import {connect} from "react-redux";
import {ErrorToast} from "./components/toasts";
import SuccessToast from "./components/toasts/SuccessToast";
import {Outlet} from "react-router-dom";


function App(props) {

    return (
        <React.Fragment>
            <div id={'top-nav-container'}>
                <TopNav/>
            </div>

            <div id={'view-container'}>
                <Outlet/>
            </div>

            <ToastContainer position="bottom-end" className="p-3 position-fixed">
                {Object.values(props.toasts).map(toast => {
                    if (toast.type === 'warning' || toast.type === 'error') {
                        return ErrorToast(toast);
                    } else {
                        return SuccessToast(toast);
                    }
                })}
            </ToastContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    toasts: state.toasts.toastObjects
});

export default connect(mapStateToProps)(App);
