import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import client from "../../services/apollo";
import {
    getAttributes
} from "./get_attributes";

const initialState = {
    status: 'idle',
    error: null,
    attributes: {},
    filters: {}
};

export const fetchAttributesThunk = createAsyncThunk(
    'attributes/fetchAttributesThunk',
    () =>
        client.query({
            query: getAttributes()
        }).catch(console.log)
)

const AttributeSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {
        toggleFilter(state, action) {
            if (state.filters[action.payload.id]) {
                delete state.filters[action.payload.id];
            } else {
                state.filters = {
                    ...state.filters,
                    [action.payload.id]: {...action.payload}
                };
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAttributesThunk.fulfilled, (state, action) => {
                state.attributes = action.payload['data']['attributes']['edges'].reduce(
                    (obj, edge) => {
                        return {
                            ...obj,
                            [edge.node.id]: edge.node
                        }
                    },
                    {} // Empty object to init the reduce function
                );

                state.status = "fulfilled";
            })
            .addCase(fetchAttributesThunk.pending, (state) => {
                state.status = "pending";
            })
            .addCase(fetchAttributesThunk.rejected, state => {
                state.status = "rejected";
            });
    }
});

export default AttributeSlice.reducer;

export const {toggleFilter} = AttributeSlice.actions;