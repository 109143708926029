import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    toastObjects: {}
};

const ToastSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToast(state, action) {
            let key = Math.random().toString(36).slice(2);
            state.toastObjects[key] = {...action.payload, key};
        },
        deleteToast(state, action) {
            delete state.toastObjects[action.payload];
        }
    }
});

export default ToastSlice.reducer;
export const {addToast, deleteToast} = ToastSlice.actions;