import {gql} from "@apollo/client";

const getShippingZones = () => gql`
    query MyQuery {
        shippingZones(first: 100) {
            edges {
                node {
                    id
                    name
                    shippingMethods {
                        id
                        name
                    }
                }
            }
        }
    }`;

export {
    getShippingZones
}