import {gql} from "@apollo/client";

export const checkoutRemovePromoCode = (token, promoCode) => gql`
mutation {
  checkoutRemovePromoCode(
    token: "${token}"
    promoCode: "${promoCode}"
  ) {
    checkout {
      id
      token
      totalPrice {
        gross {
          amount
          currency
        }
      }
      voucherCode
      isShippingRequired
      created
      lines {
        id
        quantity
        totalPrice {
          currency
          gross {
            amount
          }
        }
        undiscountedTotalPrice {
          amount
        }
        undiscountedUnitPrice {
          amount
        }
        variant {
          id
          name
          metadata {
            key
            value
          }
          product {
            id
            name
            metadata {
              key
              value
            }
            productType {
              id
              name
              metadata {
                key
                value
              }
            }
          }
          pricing {
            price {
              gross {
                amount
                currency
              }
            }
          }
        }
      }
    }
  }
}`