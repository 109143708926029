import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import store from './store/store';
import {CookiesProvider} from "react-cookie";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ServiceSelection from "./views/products/ProductListView";
import CompleteCheckout from "./views/complete/CompleteCheckout";
import Confirmation from "./views/confirmation/Confirmation";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "services",
                element: <ServiceSelection />
            },
            {
                path: "residential",
                element: <ServiceSelection />
            },
            {
                path: "business",
                element: <ServiceSelection isBusiness />
            },
            {
                path: "complete",
                element: <CompleteCheckout />
            },
            {
                path: "confirmation",
                element: <Confirmation />
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CookiesProvider>
                <RouterProvider router={router} />
            </CookiesProvider>
        </Provider>
    </React.StrictMode>
);