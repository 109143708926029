import {Nav, Navbar} from "react-bootstrap";

const linkStyle = {
    fontFamily: "Montserrat, sans-serif",
    fontSize: ".8rem",
    fontWeight: 600
};

const newTopNav = (props) => {
    return (
        <Navbar aria-label="topnav" role={"topnav"} bg="light" expand="lg" className={"shadow-lg fixed-top"}>
            <Navbar.Brand href="https://acd.net/index.cfm" className={"py-3 px-4"}>
                <img
                    src={"https://acd.net/Internal_Code/site_content/Images/ACD_Site_Images/Logos/acd.PNG"}
                    alt={"ACD"}
                    style={{height: '65px', width: '150px'}}/>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className={"justify-content-end"}>
                <Nav className={"pe-4"}>
                    <Nav.Link href="https://acd.net/index.cfm" className={"text-uppercase pe-4"} style={linkStyle}>
                        Home
                    </Nav.Link>
                    <Nav.Link href="https://acd.net/Commercial/Commercial.cfm" className={"text-uppercase pe-4"} style={linkStyle}>
                        <span>Commercial</span>
                    </Nav.Link>
                    <Nav.Link href="https://acd.net/Residential/residential.cfm" className={"text-uppercase pe-4"} style={linkStyle}>
                        <span>Residential</span>
                    </Nav.Link>
                    <Nav.Link href="https://acd.net/myaccountnew/index.cfm" className={"text-uppercase pe-4 text-nowrap"} style={linkStyle}>
                        <span>My Account</span>
                    </Nav.Link>
                    <Nav.Link href="https://acd.net/misc/support.cfm" className={"text-uppercase pe-4"} style={linkStyle}>
                        <span>Support</span>
                    </Nav.Link>
                    <Nav.Link href="https://acd.net/careers" className={"text-uppercase pe-4"} style={linkStyle}>
                        <span>Careers</span>
                    </Nav.Link>
                    <Nav.Link href="https://acd.net/misc/more.cfm" className={"text-uppercase pe-4"} style={linkStyle}>
                        <span>More</span>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>

        </Navbar>
    )
}

export default newTopNav;