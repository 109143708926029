import React from "react";
import {connect} from "react-redux";
import {Formik} from "formik";
import store from "../../store/store";
import {checkoutRemovePromoCodeThunk} from "../../store/checkout/checkout_slice";
import CodeAppliedForm from "../../components/forms/CodeAppliedForm";

const getInitialValues = () => ({
    code: store.getState().checkout.checkout.voucherCode
});

const formValidator = () => {
    return {};
};

const submitHandler = async (values, {setSubmitting}) => {
    console.log(values);
    let response = await store.dispatch(checkoutRemovePromoCodeThunk(values.code));
    console.log(response);
    setSubmitting(false);
}

const CodeApplied = () => {
    return (
        <Formik validateOnBlur
                enableReinitialize
                initialValues={getInitialValues()}
                validate={formValidator}
                onSubmit={submitHandler}>
            {CodeAppliedForm}
        </Formik>

    )
};

const mapStateToProps = (state, ownProps) => ({
    navHandler: ownProps.navHandler,
    checkout: state.checkout
});

export default connect(mapStateToProps)(CodeApplied);