import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import CodeInput from "../inputs/CodeInput";

const CodeAppliedForm = (props) => (
    <React.Fragment>
        <Row>
            <Col className={"col-7"}>
                <CodeInput {...props} name={"code"}
                           validFeedback={"Promo code applied"}
                           hasValidation={true}
                           disabled={true} />
            </Col>
            <Col className={"col-5"}>
                <Button variant={"outline-danger"}
                        disabled={props.isSubmitting}
                        onClick={props.handleSubmit}>
                    Remove
                </Button>
            </Col>
        </Row>
        <Row>
        </Row>
    </React.Fragment>
);

export default CodeAppliedForm;