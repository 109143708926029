import React from "react";
import {ListGroup} from "react-bootstrap";

const TermsAndConditions = props => {
    return (
        <ListGroup as="ol" numbered>
            <ListGroup.Item as="li">
                <p>ACD.net, Inc. ("ACD.net") exercises no control whatsoever over the
                    content of the information passing through its network. ACD.net makes no
                    warranties of any kind, whether express or implied, for the service it
                    is providing. ACD.net also disclaims any warranty of merchantability or
                    fitness for a particular purpose. ACD.net will not be responsible for
                    any damage Customer suffers. This includes loss of data resulting from
                    delays, nondeliveries, misdeliveries, or service interruptions. Use of
                    any information obtained via ACD.net's network is at Customer's own
                    risk. ACD.net specifically denies any responsibility for the accuracy or
                    quality of information obtained through its services.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>All use of the ACD.net network and the service must comply with the
                    then-current version of the ACD.net Acceptable Use Policy ("Policy")
                    available at the following URL:
                    https://www.acd.net/policies/acceptableuse.cfm. ACD.net reserves the
                    right to change the Policy without notice, effective upon posting of the
                    revised Policy at the URL. ACD.net reserves the right to suspend the
                    service or terminate this Agreement effective upon notice for a
                    violation of the Policy. Customer agrees to indemnify and hold harmless
                    ACD.net from any losses, damages, costs or expenses resulting from any
                    third party claim or allegation ("Claim") arising out of or relating to
                    use of the service, including any Claim which, if true, would constitute
                    a violation of the Policy.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Due to digital subscriber line ("DSL") technology characteristics, the
                    maximum DSL Internet access speed deliverable to you at the Premises
                    cannot be finally determined until time of installation. In the event
                    the ordered DSL service speed is not deliverable to you, you shall
                    automatically receive and accept the next lowest available DSL service
                    speed which we offer, at the applicable lower rate. If we advise you
                    that the maximum obtainable speed is lower than 1200Kbps for ADSL,
                    384Kbps for Reach DSL, or 256Kbps for 384Kbps SDSL, you may provide us
                    with written notice of termination of the Service agreement within (5)
                    business days of such notice from us, in which case the Service
                    Agreement shall be terminated at no liability to you if ACD.net provided
                    equipment is returned in original condition and packaging. Otherwise,
                    your sole option hereunder shall be to cancel the Service Agreement upon
                    written notice in accordance with section 7 of these Terms & Conditions.
                    Connection speed to sites or networks external of ACD.net cannot be
                    guaranteed and availability of Internet services, as always, depends on
                    the site and sites available recourses.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Your Committed Information Rate (CIR) is twenty percent of your maximum
                    capacity of your available DSL bandwidth for both upload and
                    download.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>IP Addresses assigned from ACD.net's block are non-portable. IP Addresses
                    allocated by ACD.net must be returned to ACD.net in the event Customer
                    discontinues service.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>The monthly service fee will be prorated to, and charged to, your credit
                    card on or about the 1st of each month. Service is charged/invoiced in
                    non-refundable 30 day increments and will not be prorated. All other
                    fees incurred "e.g. startup fees or termination fees" will be charged to
                    your credit card at time of issuance. If your Credit card is declined
                    you will receive an emailed notification. If the monthly transaction is
                    not resolved within 7 days of the notification, your account will be
                    considered in default. Unresolved accounts may have service interrupted.
                    Such interruption does not relieve Customer of the obligation to pay the
                    monthly charge. Accounts in default are subject to an interest charge of
                    the lesser of 1.5% per month, or the maximum rate permitted by law, on
                    the outstanding balance. ACD.net reserves the right to change the rates
                    of non-contract customers without notice. We may terminate any or all
                    services, without additional notice, if payment is not received when
                    due. If after termination you desire to restart the services, you may be
                    required to pay a restart fee of $100.00 and/or deposits to prepay for
                    services. Except as expressly set forth herein, all fees and charges are
                    nonrefundable.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Service may be canceled only by 10 days advanced written notice.
                    Customers with One Year Agreements who cancel or whose service is
                    terminated for any reason within the first year after startup will be
                    assessed a $150.00 early termination fee. DSL Customers with an Eighteen
                    month or Two Year Agreement who cancel/terminate within the first year
                    after startup will be assessed a $250.00 early termination fee plus. DSL
                    Customers with an Eighteen Month or Two Year Agreement who
                    cancel/terminate within the second year after startup will be assessed a
                    $150.00 early termination fee. The early termination fee for T1
                    Customers is 50% of the remaining months in the selected agreement term.
                    Termination fees are due and charged to the credit card on file on date
                    of termination notice. ACD.net, at it's sole discretion, may waive a
                    portion of the termination fees with return of modem.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Moves/Relocations - If at any point during your contracted period, you
                    request that service be moved to a new address, a $50.00 fee will be
                    assessed. All premise wiring is billed at our standard rates. If, for
                    any reason, we are unable to deliver a loop to your new address your
                    contract will be considered as an "early termination" and termination
                    fees as outlined in paragraph 7 will be assessed.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Service Speed Changes - At any time you may upgrade/downgrade your
                    service for a $25.00 administrative fee, payable at time of upgrade,
                    subject to availability.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Setup of workstation(s) and/or server(s) and/or premise wiring is not
                    included in monthly fee.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Residential DSL may only be provisioned for a "residential address."
                    Orders submitted on this form with a business address will be terminated
                    and customer will be responsible for all costs incurred by ACD.net.
                    Contact 517-333-0900 for business DSL pricing.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>In the event that the copper provider (SBC/Ameritech) requires
                    conditioning on your DSL line, you will be advised of the additional
                    charges, and may at that time, either agree to pay the additional
                    charges or cancel this contract without penalty. If charges are
                    accepted, the service speed has no guarantee and is at your own
                    risk.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Canceling an In Process Order must be made in writing and subject to the
                    following terms: After you submit your order, you may cancel order
                    within three (3) business days without penalty. Customers canceling
                    their Agreement between the three-day waiting period and the date the
                    DSL loop is to be physically installed (not the day of), a $100.00 order
                    cancellation fee will be assessed.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>ACD.net will provide telephone support to configure one workstation for
                    Internet access. Onsite or additional workstation(s), server(s),
                    router(s) or network configuration will be billed at our standard
                    service rate.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Installation Services does not include connecting the DSL modems to the
                    computer or computers, or any Ethernet cabling needed.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Customer agrees to pay ACD.net its reasonable expenses, including
                    attorney and collection agency fees, incurred in enforcing ACD.net's
                    rights under these Terms and Conditions. All contracts/agreements and
                    transactions between ACD.net and customer shall be governed by and
                    constructed in accordance with the laws of Michigan.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>The failure of either party to enforce at any time any provision of these
                    terms and conditions shall not be construed to be a waiver of such
                    provision and is independent of any other provision. Any waiver granted
                    hereunder must be in writing and signed by the party against whom it is
                    sought to be enforced.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Customer hereby allows ACD.net and/or ACD Telecom to access their Telco
                    records w/ SBC. ACD.net will not make any changes to your phone service
                    nor record any information for further use.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Customer is responsible for any and all federal, state, local tax or any
                    other tax on both the monthly service and any equipment or service
                    outlined in this agreement.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>Line sharing orders: If you SBC telephone service is interrupted for any
                    reason, including non-payment or if you change to another local
                    provider, your DSL will be interrupted. If it is not reconnected you
                    will be responsible for the charges outlined in section 7 above.</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
                <p>These Terms and Conditions supersede all previous representations,
                    understandings or agreements and shall prevail notwithstanding any
                    variance with terms and conditions of any order submitted.
                    Agreement/Contract is non-transferable. Use of ACD.net's Network
                    constitutes acceptance of these Terms and Conditions.</p>
            </ListGroup.Item>
        </ListGroup>
    )
};

export default TermsAndConditions;