import {gql} from "@apollo/client";

export const checkoutBillingAddressUpdate = ({token, userInfo, addressInfo}) => {
    return gql`
        mutation checkoutBillingAddressUpdateMutation {
            checkoutBillingAddressUpdate(
                billingAddress: {
                    city: "${addressInfo.city}",
                    country: US,
                    countryArea: "${addressInfo.countryArea}",
                    firstName: "${userInfo.firstName}",
                    lastName: "${userInfo.lastName}",
                    phone: "${userInfo.phone}",
                    postalCode: "${addressInfo.postalCode}",
                    streetAddress1: "${addressInfo.streetAddress1}",
                    streetAddress2: "${addressInfo.streetAddress2}"
                },
                token: "${token}"
            ) {
                errors {
                    field
                    message
                    code
                }
                checkout {
                    id
                    token
                    totalPrice {
                        gross {
                            amount
                            currency
                        }
                    }
                    voucherCode
                    isShippingRequired
                    created
                    lines {
                        id
                        quantity
                        totalPrice {
                            currency
                            gross {
                                amount
                            }
                        }
                        undiscountedTotalPrice {
                            amount
                        }
                        undiscountedUnitPrice {
                            amount
                        }
                        variant {
                            id
                            name
                            metadata {
                                key
                                value
                            }
                            product {
                                id
                                name
                                metadata {
                                    key
                                    value
                                }
                                productType {
                                    id
                                    name
                                    metadata {
                                        key
                                        value
                                    }
                                }
                            }
                            pricing {
                                price {
                                    gross {
                                        amount
                                        currency
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`
}

export const checkoutEmailAddressUpdate = ({token, userInfo}) => {
    return gql`
        mutation checkoutEmailUpdate {
            checkoutEmailUpdate(
                email: "${userInfo.email}",
                token: "${token}"
            ) {
                errors {
                    field
                    message
                    code
                }
                checkout {
                    id
                    token
                    totalPrice {
                        gross {
                            amount
                            currency
                        }
                    }
                    isShippingRequired
                    created
                    lines {
                        id
                        quantity
                        variant {
                            id
                            name
                            metadata {
                                key
                                value
                            }
                            product {
                                id
                                name
                                metadata {
                                    key
                                    value
                                }
                                productType {
                                    id
                                    name
                                    metadata {
                                        key
                                        value
                                    }
                                }
                            }
                            pricing {
                                price {
                                    gross {
                                        amount
                                        currency
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`
}

export const checkoutShippingAddressUpdate = ({token, userInfo, addressInfo}) => gql`
    mutation checkoutShippingAddressUpdateMutation {
        checkoutShippingAddressUpdate(
            shippingAddress: {
                city: "${addressInfo.city}",
                country: US,
                countryArea: "${addressInfo.countryArea}",
                firstName: "${userInfo.firstName}",
                lastName: "${userInfo.lastName}",
                phone: "${userInfo.phone}",
                postalCode: "${addressInfo.postalCode}",
                streetAddress1: "${addressInfo.streetAddress1}",
                streetAddress2: "${addressInfo.streetAddress2}"
            },
            token: "${token}"
        ) {
            errors {
                field
                message
                code
            }
            checkout {
                id
                token
                totalPrice {
                    gross {
                        amount
                        currency
                    }
                }
                isShippingRequired
                created
                lines {
                    id
                    quantity
                    variant {
                        id
                        name
                        metadata {
                            key
                            value
                        }
                        product {
                            id
                            name
                            metadata {
                                key
                                value
                            }
                            productType {
                                id
                                name
                                metadata {
                                    key
                                    value
                                }
                            }
                        }
                        pricing {
                            price {
                                gross {
                                    amount
                                    currency
                                }
                            }
                        }
                    }
                }
            }
        }
    }`