import {gql} from "@apollo/client";

const createCheckout = () => gql`
mutation MyQuery ($channel: String, $email: String) {
  checkoutCreate(input: {channel: $channel, email: $email, lines: []}) {
    checkout {
      id
      token
      totalPrice {
        gross {
          amount
          currency
        }
      }
      voucherCode
      isShippingRequired
      created
      availableShippingMethods {
        id
        name
      }
      availablePaymentGateways {
        id
        name
        config {
          field
          value
        }
      }
      lines {
        id
        quantity
        totalPrice {
          currency
          gross {
            amount
          }
        }
        undiscountedTotalPrice {
          amount
        }
        undiscountedUnitPrice {
          amount
        }
        variant {
          id
          name
          metadata {
            key
            value
          }
          product {
            id
            name
            metadata {
              key
              value
            }
            productType {
              id
              name
              metadata {
                key
                value
              }
            }
          }
          pricing {
            price {
              gross {
                amount
                currency
              }
            }
          }
        }
      }
    }
  }
}
`;

export {
    createCheckout
}