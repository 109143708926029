import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import CodeInput from "../inputs/CodeInput";

const CodeForm = (props) => (
    <React.Fragment>
        <Row>
            <Col className={"col-7"}>
                <CodeInput {...props} name={"code"} />
            </Col>
            <Col className={"col-5"}>
                <Button variant={"outline-success"} disabled={props.isSubmitting} onClick={props.handleSubmit}>
                    Apply
                </Button>
            </Col>
        </Row>
        <Row>
        </Row>
    </React.Fragment>
);

export default CodeForm;