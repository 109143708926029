import {configureStore} from "@reduxjs/toolkit";
import AttributesSlice from './attributes/attributes_slice';
import ProductsSlice from './products/products_slice';
import CheckoutSlice from './checkout/checkout_slice';
import ToastSlice from './toasts/toasts_slice';
import ShippingZonesSlice from './shippingZones/shipping_zones_slice';
import ShippingMethodsSlice from './shippingMethods/shipping_methods_slice';

export const store = configureStore({
    reducer: {
        attributes: AttributesSlice,
        toasts: ToastSlice,
        checkout: CheckoutSlice,
        products: ProductsSlice,
        shippingMethods: ShippingMethodsSlice,
        shippingZones: ShippingZonesSlice
    },
});

export default store;