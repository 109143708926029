import {Button, Col, ListGroupItem, Row} from "react-bootstrap";
import {BsTrash} from "react-icons/bs";
import React from "react";
import store from "../../store/store";
import {removeCheckoutLineThunk} from "../../store/checkout/checkout_slice";
import {addToast} from "../../store/toasts/toasts_slice";

const isDisabled = (line) => {
    console.log({lineBeforeFind: line});
    let isBundle = line.variant?.product?.metadata?.find(md => md.key === 'bundle_item');
    return !!(isBundle && isBundle.value);
}
const onRemoveItemHandler = (props, line) => () => {
    let checkoutState = store.getState().checkout.checkout;
    let hasBundleItems = line.variant["product"]["metadata"].find(md => md.key === 'bundled_variants');
    if (hasBundleItems && hasBundleItems.value) {
        let variantIds = hasBundleItems.value.split(",");

        console.log(variantIds);

        let linesToRemove = props.checkout.checkout.lines.filter(line => variantIds.find(variantId => line.variant.id === variantId));

        console.log(linesToRemove);

        let linesIds = [...linesToRemove, line].map(line => line.id);
        console.log(linesIds);

        store.dispatch(removeCheckoutLineThunk(linesIds));
    } else {
        console.log({
            voucher: checkoutState.voucherCode,
            lines: checkoutState.lines.length
        })
        if (!checkoutState.voucherCode || checkoutState.lines.length > 1) {
            store.dispatch(removeCheckoutLineThunk(line.id));
        } else {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Promo Code Error',
                message: 'Remove promo code before removing all products from the cart'
            }))
        }
    }
}

const priceDisplay = (line) => {
    console.log({udp: line.undiscountedTotalPrice.amount, tp: line.totalPrice.gross.amount});
    let discountLength = line.variant.product.metadata.find(md => md.key === 'subscription_discount_length');
    let subscriptionPeriod = line.variant.product.metadata.find(md => md.key === 'subscription_period');

    if (line.undiscountedTotalPrice.amount > line.totalPrice.gross.amount) {
        return (
            <React.Fragment>
                <span>${line.totalPrice.gross.amount}</span>
                {
                    line.variant.product.productType.metadata.some(md => md.key === 'subscription') &&
                    <span>/mo</span>
                }
                {
                    discountLength &&
                    <span className={"text-nowrap ps-1"}>
                        <sup>
                            (first {discountLength.value} {subscriptionPeriod.value}s)
                        </sup>
                    </span>
                }
                <br/>
                <sub className={"d-inline-block"}>
                    <del>
                        <span>${line.undiscountedTotalPrice.amount}</span>
                        {
                            line.variant.product.productType.metadata.some(md => md.key === 'subscription') &&
                            <span>/mo</span>
                        }
                    </del>
                </sub>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <span>${line.totalPrice.gross.amount}</span>
                {
                    line.variant.product.productType.metadata.some(md => md.key === 'subscription') &&
                    <span>/mo</span>
                }
            </React.Fragment>
        )
    }
}

const CartLineItem = (props) => (line) => {
    return (
        <ListGroupItem key={line.id} className={"border-0"}>
            <Row className={"text-muted justify-content-lg-between"} style={{fontSize: '.8rem'}}>
                <Col className={"col-5 col-md-5 col-lg-5 pe-0"}>
                    <span><b>{line.variant.product.name}</b></span>
                </Col>
                <Col className={"col-1 col-md-1 col-lg-1 p-0"}>
                    <span>x{line.quantity}</span>
                </Col>
                <Col className={"col-4 col-md-3 col-lg-3 ps-0"}>
                    {priceDisplay(line)}
                </Col>
                <Col className={"col-2 col-md-3 col-lg-3 d-flex justify-content-end"}>
                    {!props.hideRemoveButtons && !isDisabled(line) && (
                        <Button variant={"outline-danger"}
                                size={"sm"}
                                onClick={onRemoveItemHandler(props, line)}>
                            <BsTrash/>
                        </Button>
                    )}
                </Col>
            </Row>
        </ListGroupItem>
    )
}

export default CartLineItem;